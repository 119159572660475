import React, {useEffect, useState} from "react";
import {axiosCommon} from "../../util/axiosSetup.js";
import {BASE_URL} from "../../util/Urls";
import {
    Avatar,
    Button, Chip,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useMediaQuery, useTheme
} from "@material-ui/core";
import formatPaymentType from "../../util/formatPaymentType";
import formatNumber from "../../util/formatNumber";
import formatCurrency from "../../util/formatCurrency";
import formatDateTime from "../../util/formatDateTime";
import {
    getCartDiscountValue,
    getCartItemDiscountValue, getCartItemOriginalPriceForOne,
    getCartItemPrice, getCartItemPriceForOne,
    getCartServiceFee, getCartTotalPrice
} from "../../util/CartPriceCalculator";

function ShowCarts (props){
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [carts, setCarts] = useState([]);
    const [error, setError] = useState('');
    const returnColor = 'rgb(248, 147, 124)'

    useEffect(() => {
        if (props.orderLocationId) {
            axiosCommon.get(`${BASE_URL}/api/carts?status=ACTIVE&orderLocationId=${props.orderLocationId}`)
                .then(response => {
                    setCarts(response.data.items);
                })
                .catch(error => {
                    setError(error);
                })
        } else if(props.cartId){
            axiosCommon.get(`${BASE_URL}/api/carts/${props.cartId}`)
                .then(response => {
                    setCarts([response.data]);
                })
                .catch(error => {
                    setError(error);
                })
        }
        else {
            const queryParams = {}
            if (props.employeeConsumerId) {
                queryParams.employeeConsumerId = props.employeeConsumerId
            } else {
                queryParams.consumerId = props.consumerId
            }
            axiosCommon.get(`${BASE_URL}/api/carts?status=ACTIVE`,
                {
                    params: queryParams
                })
                .then(response => {
                    setCarts(response.data.items);
                })
                .catch(error => {
                    setError(error);
                })
        }
    }, []);

    const consumer = (cart) => {
        if (cart.employeeConsumer) {
            return `${cart.employeeConsumer.lastName} ${cart.employeeConsumer.firstName}`
        }
        if (cart.consumer) {
            return `${cart.consumer.lastName} ${cart.consumer.firstName}`
        }
        return `Regisztrálatlan vásárló`
    }

    const renderProductPrice = (cartItem) => {
        return cartItem.originalProductPrice == cartItem.productPrice
            ? formatNumber(cartItem.productPrice,0)
            : <><span className="correction">{formatNumber(cartItem.productPrice,0)}</span> (<span
                className="corrected">{formatNumber(cartItem.originalProductPrice,0)}</span>)</>
    }

    const renderToppingPrice = (cartItem) => {
        if(cartItem.toppings == null || cartItem.toppings.length == 0) return 0
        const toppingOriginalPrice = cartItem.toppings.map(topping => topping.originalProductPrice * topping.quantity).reduce((a, b) => a + b, 0)
        const toppingPrice = cartItem.toppings.map(topping => topping.productPrice * topping.quantity).reduce((a, b) => a + b, 0)

        return toppingOriginalPrice == toppingPrice
            ? formatNumber(toppingPrice,0)
            : <><span className="correction">{formatNumber(toppingPrice,0)}</span> (<span
                className="corrected">{formatNumber(toppingOriginalPrice,0)}</span>)</>
    }

    const formatDiscount = (discount) => {
        const {name} = discount
        const amount = discount.percent ? `${discount.percent}%`: `${discount.difference}Ft`
        return `${name} (${amount}), `
    }

    const getDiscounts = (cartDiscounts, productDiscounts) => {
        const cartDiscountText = cartDiscounts.map(d => formatDiscount(d))
        const productDiscountText = productDiscounts.map(d => formatDiscount(d))

        return cartDiscountText.concat(productDiscountText)
    }

    const getCartItemToppings = (cartItem) => {
        const result = []

        if(cartItem.bottleDeposit != null && cartItem.bottleDeposit > 0){
            result.push(
                <Chip
                    key={-1}
                    avatar={<Avatar style={{backgroundColor: "lightblue", color: "black"}}>1</Avatar>}
                    size="small"
                    style={{marginRight: 3, marginBottom: 3}}
                    label={`Betétdíj (+${cartItem.bottleDeposit}Ft)`}
                />
            )
        }

        if(cartItem.toppings != null && cartItem.toppings.length > 0){
            cartItem.toppings.forEach((topping, i) => {
                result.push(
                    <Chip
                        key={i}
                        avatar={<Avatar style={{backgroundColor: "lightblue", color: "black"}}>{topping.quantity}</Avatar>}
                        size="small"
                        style={{marginRight: 3, marginBottom: 3}}
                        label={`${topping.product.name} (+${topping.originalProductPrice + topping.bottleDeposit}Ft)`}
                    />
                )
            })
        }

        if(result.length > 0) return result
        return undefined
    }

    return (
        <Dialog
            maxWidth='lg'
            fullWidth
            fullScreen={fullScreen}
            open={props.open}
            onClose={props.onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">Részletek</DialogTitle>
            <DialogContent>
                {carts.map((c, i) =>
                    <section key={i}>
                        <Typography style={{fontWeight: 500, marginBottom: 8}}>
                            {`${formatDateTime( c.paymentDateTime == null ? c.creationDateTime : c.paymentDateTime)}`}
                        </Typography>
                        {c.isDelivery === true
                            ?<>
                                <Typography variant="body1" style={{color: "blue"}}>Kiszállítás</Typography>
                                <Typography variant="body1" style={{color: "blue"}}>{consumer(c)}</Typography>
                                <Typography variant="body1" style={{color: "blue"}}>{`${c.consumer?.address?.zipCode || ""} ${c.consumer?.address?.city || ""} ${c.consumer?.address?.street || ""}`}</Typography>
                                <Typography variant="body1" style={{color: "blue"}}>Futár: {c.deliveryPerson || ""}</Typography>
                            </>
                            :<Typography variant="body1" style={{color: "blue"}}>Vásárló: {consumer(c)}</Typography>
                        }

                        <Typography variant="body1" style={{marginTop: 8}}>Rendelés felvétel: {`${c.initiator.lastName} ${c.initiator.firstName}`}</Typography>
                        <Typography variant="body1">Fizettetés: {c.paymentInitiator != null ? `${c.paymentInitiator.lastName} ${c.paymentInitiator.firstName}` : "-"}</Typography>
                        <Typography variant="body1">Fizetési eszköz: {c.paymentType == null ? "-" : formatPaymentType(c.paymentType)}</Typography>
                        <Typography variant="body1">Blokk sorszáma: {c.invoiceNumber != null ? c.invoiceNumber : "-"}</Typography>
                        {c.fiscalData?.fiscalSell != null
                            ? <Typography variant="body1">Nyugta: {c.fiscalData.fiscalSell}</Typography>
                            : undefined
                        }
                        <Typography variant="body1">
                            ÁFÁ-s számla: {(c.billingInvoiceId == -1 && c.billingInvoiceNumber == null) ? "Nincs" : `${c.billingInvoiceNumber} `}
                            {c.billingInvoiceLink != null
                                ? <a target="_blank" href={c.billingInvoiceLink} rel="noreferrer">(Megnézem)</a>
                                : undefined
                            }

                        </Typography>

                        {c.isCartReturned
                            ?<>
                                <Typography variant="body1" style={{color: returnColor, fontWeight: 500}}>
                                    Sztornó időpontja: {formatDateTime( c.returnCartDateTime)}
                                </Typography>
                                <Typography variant="body1" style={{color: returnColor, fontWeight: 500}}>
                                    Sztornózó: {`${c.returnCartInitiator.lastName} ${c.returnCartInitiator.firstName}`}
                                </Typography>
                                {c.fiscalData?.fiscalStorno != null
                                    ? <Typography variant="body1" style={{color: returnColor, fontWeight: 500}}>
                                        Sztorno nyugta: {c.fiscalData.fiscalStorno}
                                    </Typography>
                                    : undefined
                                }
                                <div className='cart-return-view'>
                                    <Typography variant="h1" className='cart-return-text'>
                                        Sztornózva
                                    </Typography>
                                </div>
                            </>
                            : undefined
                        }

                        {c.status === 'DELETED'
                            ?<>
                                <Typography variant="body1" style={{color: returnColor, fontWeight: 500}}>
                                    Törlés időpontja: {formatDateTime( c.deleteDateTime)}
                                </Typography>
                                <Typography variant="body1" style={{color: returnColor, fontWeight: 500}}>
                                    Törölte: {`${c.deleteInitiator.lastName} ${c.deleteInitiator.firstName}`}
                                </Typography>
                            </>
                            : undefined
                        }

                        <TableContainer style={{marginTop: 24}}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Név</TableCell>
                                        <TableCell>Feltétek</TableCell>
                                        <TableCell align="center">Eredeti ár (Ft)</TableCell>
                                        <TableCell align="center">Kedv.</TableCell>
                                        <TableCell align="center">Végső ár (Ft)</TableCell>
                                        <TableCell align="center">Mennyiség</TableCell>
                                        <TableCell align="center">Összes kedv. (Ft)</TableCell>
                                        <TableCell align="center">Végösszeg (Ft)</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {c.items.map((cartItem) => {
                                        return <TableRow key={cartItem.id} hover>
                                            <TableCell>{cartItem.product.name + (cartItem.isTakeAway === true ? " (Elv.)" : "")}</TableCell>
                                            <TableCell>{getCartItemToppings(cartItem)}</TableCell>
                                            <TableCell align="center">{getCartItemOriginalPriceForOne(cartItem)}</TableCell>
                                            <TableCell align="center">{getDiscounts(c.discounts, cartItem.discounts)}</TableCell>
                                            <TableCell align="center">{getCartItemPriceForOne(cartItem)}</TableCell>
                                            {c.isCartReturned || c.status === "DELETED"
                                                ? <TableCell align="center">
                                                    {cartItem.quantity}
                                                    <div style={{color: returnColor, fontWeight: 500}}>(Visszavett: {cartItem.returnStockQuantity})</div>
                                                </TableCell>
                                                : <TableCell align="center">{cartItem.quantity}</TableCell>
                                            }
                                            <TableCell align="center">{formatNumber(getCartItemDiscountValue(cartItem),0)}</TableCell>
                                            <TableCell align="center">{formatNumber(getCartItemPrice(cartItem), 0)}</TableCell>
                                        </TableRow>
                                    })}
                                    <TableRow style={{borderBottom: "2px solid lightgray"}}/>
                                    {c.serviceFeePercent != 0
                                        ?<TableRow hover>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell align="center">Szervizdíj</TableCell>
                                            <TableCell/>
                                            <TableCell align="center">
                                                + {formatCurrency(getCartServiceFee(c), 0, "HUF")}
                                            </TableCell>
                                        </TableRow>
                                        : undefined
                                    }
                                    <TableRow hover style={{background: "lightblue"}}>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell/>
                                        <TableCell align="center" style={{fontWeight: 'bold'}}>Összesen</TableCell>
                                        <TableCell align="center" style={{fontWeight: 'bold'}}>
                                            {formatCurrency(getCartDiscountValue(c),0,"HUF")}
                                        </TableCell>
                                        <TableCell align="center" style={{fontWeight: 'bold'}}>
                                            {formatCurrency(getCartTotalPrice(c),0,"HUF")}
                                        </TableCell>
                                    </TableRow>
                                    {c.tip
                                        ?<TableRow hover>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell/>
                                            <TableCell align="center">Borravaló</TableCell>
                                            <TableCell/>
                                            <TableCell align="center">
                                                {formatCurrency(c.tip || 0,0,"HUF")}
                                            </TableCell>
                                        </TableRow>
                                        : undefined
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </section>
                )}
            </DialogContent>
            <DialogActions style={{marginTop: 20}}>
                <Button onClick={props.onClose} color="primary">
                    Bezár
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ShowCarts;

