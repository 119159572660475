import React from "react";
import {axiosCommon} from "../util/axiosSetup.js";
import {BASE_URL} from "../util/Urls";
import Card from "./Card";
import Widget from "./Widget";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {Button, CircularProgress} from "@material-ui/core";
import getGeneralError from "../util/getGeneralError";
import formatEmail from "../util/formatEmail";

export default class EditProfile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: {
                lastName: null,
                firstName: null,
                email: null,
                password: '',
                address: {
                    street: null,
                    city: null,
                    zipCode: null,
                },
                enabled: true,
                nfcCode: null,
            },
            passwordAgain: '',
            isSubmitInProgress: false,
        };
    }

    componentDidMount() {
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== this.state.user.password) {
                return false;
            }
            return true;
        });

        axiosCommon.get(`${BASE_URL}/api/users/me`)
            .then(response => {
                let {user} = this.state;
                user = response.data;
                user.password = user.password ? user.password : '';
                user.companyData = undefined;
                this.setState({ user })
            })
            .catch(error => {
                this.setState({error});
            });
    }

    componentWillUnmount() {
        ValidatorForm.removeValidationRule('isPasswordMatch');
    }

    onSubmit = () => {
        if(this.state.passwordAgain !== this.state.user.password){
            this.setState({error: "A két jelszónak meg kell egyeznie"});
            return 0;
        }

        this.setState({isSubmitInProgress: true});

        let {user} = this.state;
        if(user.password === ''){
            user.password = undefined;
        }

        axiosCommon.put(`${BASE_URL}/api/users`, user)
            .then(response => {
                user = response.data;
                user.password = user.password ? user.password : '';
                user.companyData = undefined;
                this.setState({
                    user,
                    error: '',
                    isSubmitInProgress: false,
                    passwordAgain: '',
                })
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    handleChange = (event) => {
        const { user } = this.state;
        user[event.target.name] = event.target.value;
        this.setState({ user });
    }

    handleAddressChange = (event) => {
        const { address } = this.state.user;
        address[event.target.name] = event.target.value;
        this.setState({ address });
    }

    render() {
        return <>
            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Személyes adatok">

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Vezetéknév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="lastName"
                                value={this.state.user.lastName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Keresztnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="firstName"
                                value={this.state.user.firstName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Város"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="city"
                                value={this.state.user.address.city || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Irányítószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="zipCode"
                                value={this.state.user.address.zipCode || ""}
                                inputProps={{ maxLength: 10 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cím"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="street"
                                value={this.state.user.address.street || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Telefonszám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="phone"
                                value={this.state.user.phone || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const { user } = this.state
                                    user.email = formatEmail(event.target.value)
                                    this.setState({ user })
                                }}
                                name="email"
                                value={this.state.user.email || ""}
                                required
                                validators={['required', 'isEmail']}
                                errorMessages={['A mező kitöltése kötelező', 'Érvénytelen email cím']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1" >
                            <TextValidator
                                label="NFC belépő kód"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="nfcCode"
                                value={this.state.user?.nfcCode || ""}
                                inputProps={{ maxLength: 30 }}
                            />
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Jelszó megadása">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Jelszó"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="password"
                                value={this.state.user.password || ""}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Jelszó újra"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    this.setState({ passwordAgain:  event.target.value});
                                }}
                                value={this.state.passwordAgain || ""}
                                validators={['isPasswordMatch']}
                                errorMessages={['A két jelszó különböző']}
                            />
                        </div>
                    </Widget>
                </Card>

                <section style={{ marginTop: 16, fontSize: 20, color: "red", justifyContent: "center", display: "flex"}}>{getGeneralError(this.state.error)}</section>

                <div style={{justifyContent: "center", display: "flex"}}>
                    {!this.state.isSubmitInProgress
                        ? <Button type="submit" variant="contained" color="primary"
                                  style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20}}>Mentés</Button>
                        : <div style={{marginTop: 30, textAlign: "center"}}>
                            <CircularProgress color="primary"/>
                        </div>
                    }
                </div>

            </ValidatorForm>
        </>
    }
}
