import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    IconButton,
    Paper,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import DeleteIcon from "@material-ui/icons/Delete";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import DeleteDialog from "../../../components/DelteDialog";
import NewItemButton from "../../../components/NewItemButton";
import NavHeader from "../../../components/NavHeader";
import {
    getKeyword,
    getPageNumber,
    getRowsPerPage, removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";

export default class CustomerListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            keyword: "",
            customers: [],
            deleteInProgress: null,
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            isSuperAdmin: false,
            columns: [
                {field: 'name', headerName: 'Név', width: 160},
                {field: 'email', headerName: 'Email', align: 'center', headerAlign: 'center', width: 200},
                {field: 'company', headerName: 'Cég', align: 'center', headerAlign: 'center', width: 160,},
                {field: 'taxnumber', headerName: 'Adószám', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120,},
                {field: 'shopnumber', headerName: 'Üzletek', align: 'center', headerAlign: 'center', width: 110},
                {field: 'fee', headerName: 'Havidíj (Ft)', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120},
                {field: 'demo', headerName: 'Demo lejárat', align: 'center', headerAlign: 'center', flex: 1, minWidth: 100},
                {field: 'enabled', headerName: 'Állapot', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120},
                {field: 'delete', headerName: ' ', headerAlign: 'right', align: 'right', width: 60, renderCell: this.renderAction},],
            rows: [],
            isLoading: false,
        }
        this.keywordKey = 'customerListPageKeyword'
        this.pageKey = 'customerListPagePageKey'
        this.rowsPerPageKey = 'customerListPageRowsPerPageKey'
    }

    componentDidMount() {
        this.setState({isLoading: true, isSuperAdmin: sessionStorage.getItem("extended_role") === "ROLE_SUPER_ADMIN"})

        axiosCommon(`${BASE_URL}/api/users?`, { params: {deleted: false, role: "CUSTOMER"} })
        .then(response => {
            if(this.props.history.action === "POP"){
                this.setState({
                    customers: response.data.items,
                    page: getPageNumber(this.pageKey),
                    rowsPerPage: getRowsPerPage(this.rowsPerPageKey),
                    keyword: getKeyword(this.keywordKey)}, () => this.refreshData())
                removeSessionKeys([this.pageKey, this.rowsPerPageKey, this.keywordKey])
            }else {
                this.setState({ customers: response.data.items}, () => this.refreshData())
            }
        })
        .catch(error => {
            this.setState({error})
        })
    }

    componentWillUnmount(){
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    refreshData = () => {
        this.setState({ isLoading: true})
        const filteredCustomers = this.state.customers.filter(item =>
            `${item.lastName} ${item.firstName}`.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.email.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.companyData.companyName.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.companyData.taxNumber.toLowerCase().includes(this.state.keyword.toLowerCase()))

        const rows = filteredCustomers.map((e, i) => {
            return {
                id: e.id,
                name: `${e.lastName} ${e.firstName}`,
                email: e.email,
                company: e.companyData.companyName,
                taxnumber: e.companyData.taxNumber,
                shopnumber: e.shops.length,
                fee: e.monthlyFee,
                demo: e.profile?.endOfDemoPeriod != null ? e.profile.endOfDemoPeriod : "-",
                enabled: e.enabled ? "Aktív" : "Szünetel",
                delete: undefined,
            }
        });

        this.setState({ rows, isLoading: false})
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    initiateDeletion = (id) => {
        this.setState({
            deleteInProgress: id
        })
    };

    rejectDeletion = () => {
        this.setState({
            deleteInProgress: null
        })
    };

    confirmDeletion = () => {
        axiosCommon.delete(`${BASE_URL}/api/users/${this.state.deleteInProgress}`)
            .then(response => {
                this.refreshData();
            })
            .catch(error => {
                this.setState({error})
            });
        this.setState({
            deleteInProgress: null,
        })
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/admin/people/editcustomers",
            userId: id,
        });
    };

    initiateCreate = () => {
        this.props.history.push({
            pathname: "/admin/people/editcustomers",
        });
    };

    renderAction = (params) => {
        return this.state.isSuperAdmin
                ?<IconButton aria-label="delete" onClick={() => this.initiateDeletion(params.id)}>
                    <DeleteIcon />
                </IconButton>
                : undefined
    }

    renderDeleteDialog = () => {
        return <DeleteDialog
            title="Biztosan törlöd az Ügyfelet?"
            open={this.state.deleteInProgress !== null}
            onConfirm={this.confirmDeletion}
            onClose={this.rejectDeletion}
            onReject={this.rejectDeletion}
        >
            A törlés nem visszavonható, az adatok véglegesen törlésre kerülnek!
        </DeleteDialog>;
    };

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés név / cégnév / adószám / email alapján">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar style={{display: 'flex', flexDirection: 'row', }}>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Ügyfelek
                        </Typography>
                    </Toolbar>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        onPageSizeChange={(entry) =>
                            this.setState({ rowsPerPage: parseInt(entry, 10), page: 0})}
                        onPageChange={(entry) =>
                            this.setState({ page: parseInt(entry, 10)}, () => this.refreshData())}
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        pageSize={this.state.rowsPerPage}
                        pagination
                        page={this.state.page}
                        loading={this.state.isLoading}
                        onRowDoubleClick={(rowData) => this.initiateEdit(rowData.row.id)}
                        components={{
                            Toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <GridToolbarExport/>
                                    </GridToolbarContainer>
                                );
                            },
                            NoRowsOverlay: NoData,
                        }}
                    />
                </Paper>

                {this.renderDeleteDialog()}
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
            <NewItemButton
                title="Ügyfél"
                onClick={this.initiateCreate}/>
        </>;
    }
}
