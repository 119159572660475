export const FiscalSolutions = [
    {value: "NO_SOLUTION", label: "Nincs csatlakoztatás"},
    {value: "ONLINE_CASHIER", label: "Online pénztárgép"},
    {value: "FISCAL_PRINTER", label: "Adóügyi nyomtató"},
];

export const FiscalPrinters = [
    {value: "FISCAT_SUPER_FP_BEE_A234", label: "Fiscat Super FP BEE (A234)"},
    {value: "FISCAT_SUPER_FP_MAX80_A234", label: "Fiscat Super FP MAX80 (A234)"},
    {value: "CASHBOX_POSPRINT_A255", label: "Cashbox PosPrint (A255)"},
    {value: "FISCAT_CASHBOX_V7", label: "Fiscat & Cashbox V7 SW"},
    {value: "FISCAT_CASHBOX_V8", label: "Fiscat & Cashbox V8 SW"},
];

export const CashRegisterConnectionType = [
    {value: "QR_CODE", label: "QR kóddal"},
    {value: "USB", label: "USB-vel"},
    {value: "BLUETOOTH", label: "Bluetooth-al"},
];

export const QrCodeCashRegisters = [
    {value: "CASHBOX_BASE_A240", label: "Cashbox Base (A240)"},
    {value: "FISCAT_NEONPLUS_WIFI_A241", label: "Fiscat Neon+ Wifi (A241)"},
    {value: "FISCAT_NEONPLUS_A180", label: "Fiscat Neon+ (A180)"},
    {value: "FISCAT_IPALM_GPS_WIFI_A245", label: "Fiscat iPalm GPS Wifi (A245)"},
    {value: "FISCAT_IPALM_GPS_A195", label: "Fiscat iPalm GPS (A195)"},
    {value: "SAM4S_NR_300_A156", label: "Sam4s NR-300 (A156)"},
    {value: "SAM4S_NR_240_A171", label: "Sam4s NR-240 (A171)"},
    {value: "SAM4S_NR_440_A155", label: "Sam4s NR-440 (A155)"},
    {value: "CASHCUBE_LIGHT_A085", label: "Cashcube Light (A085)"},
    {value: "CASHCUBE_LIGHT_PLUS_A178", label: "Cashcube Light+ (A178)"},
];

export const UsbCashRegisters = [
    {value: "CASHBOX_BASE_A240", label: "Cashbox Base (A240)"},
    {value: "FISCAT_NEONPLUS_WIFI_A241", label: "Fiscat Neon+ Wifi (A241)"},
    {value: "FISCAT_NEONPLUS_A180", label: "Fiscat Neon+ (A180)"},
    {value: "FISCAT_IPALM_GPS_WIFI_A245", label: "Fiscat iPalm GPS Wifi (A245)"},
    {value: "FISCAT_IPALM_GPS_A195", label: "Fiscat iPalm GPS (A195)"},
    {value: "SAM4S_NR_300_A156", label: "Sam4s NR-300 (A156)"},
    {value: "SAM4S_NR_240_A171", label: "Sam4s NR-240 (A171)"},
    {value: "SAM4S_NR_440_A155", label: "Sam4s NR-440 (A155)"},
    {value: "CASHCUBE_LIGHT_A085", label: "Cashcube Light (A085)"},
    {value: "CASHCUBE_LIGHT_PLUS_A178", label: "Cashcube Light+ (A178)"},
    {value: "MICRA_MINI_S_A160", label: "Micra Mini S (A160)"},
    {value: "MICRA_MINI_M_A006", label: "Micra Mini M (A006)"},
    {value: "MICRA_JOTA_S_A159", label: "Micra Jota S (A159)"},
    {value: "MICRA_JOTA_M_A008", label: "Micra Jota M (A008)"},
    {value: "MICRA_JOTA_E_A307", label: "Micra Jota E (A307)"},
    {value: "MICRA_K10_A292", label: "Micra K10 (A292)"},
    {value: "EURO_150TE_FLEXY_A014", label: "Euro 150TE Flexy (A014)"},
    {value: "DATECS_DP25EU_A136_A091", label: "Datecs DP-25 EU & C10 (A091, A136)"},
    {value: "DATECS_DP45EU_A134", label: "Datecs DP-45 EU (A134)"},
    {value: "DATECS_DP150MO_A253", label: "Datecs DP-150 MO (A253)"},
];

export const BluetoothCashRegisters = [
    {value: "CASHBOX_BASE_A240", label: "Cashbox Base (A240)"},
    {value: "FISCAT_NEONPLUS_WIFI_A241", label: "Fiscat Neon+ Wifi (A241)"},
    {value: "FISCAT_IPALM_GPS_WIFI_A245", label: "Fiscat iPalm GPS Wifi (A245)"},
];

export const PayTerminals = [
    {value: "NO_SOLUTION", label: "Nincs"},
    {value: "TEYA", label: "Teya"},
];

export default (FiscalSolutions, FiscalPrinters, CashRegisterConnectionType, QrCodeCashRegisters, UsbCashRegisters, BluetoothCashRegisters, PayTerminals);
