import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Checkbox, FormControl,
    FormControlLabel, FormGroup,
    IconButton,
    MenuItem, Paper,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import {
    FiscalSolutions,
    FiscalPrinters,
    BluetoothCashRegisters,
    CashRegisterConnectionType,
    UsbCashRegisters,
    QrCodeCashRegisters, PayTerminals
} from "../../../util/FiscalSolutions";
import {BillingClients} from "../../../util/BillingClients";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";
import DeleteIcon from "@material-ui/icons/Delete";
import moment from "moment";

export default class EditAdminShop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shop: {
                name: null,
                cashRegisterConnection: {
                    isCashRegisterAllowed: false,
                    connectionType: "NO_CONNECTION",
                    cashRegister: null,
                    printOnReceipt: false,
                    sendDataWithNames: false,
                    separateServiceFee: false,
                },
                fiscalPrinterConnection: {
                    fiscalPrinter: null,
                    isFiscalPrinterAllowed: false,
                },
                billingConnection: {
                    isBillingAllowed: false,
                    billingClient: "NO_CLIENT",
                    apiKey: null,
                    defaultBillingBlockId: null,
                    defaultBankAccountId: null,
                    defaultInvoicePrefix: null,
                },
                payTerminalConnection: {
                    payTerminalType: null,
                    isPayTerminalAllowed: false,
                },
                scaleConnection: {
                    isScaleAllowed: false,
                },
                terminalsConnection: {
                    allowedTerminalNumber: 1,
                },
                nfcConnection: {
                    isNfcAllowed: false,
                },
                enabled: true,
            },
            fiscalSolution: "NO_SOLUTION",
            payTerminalSolution: "NO_SOLUTION",
            isSubmitInProgress: false,
            enabledPaymentTypes: [],
            serviceFeePaymentTypes: [],
            columns: [
                {field: 'device', headerName: 'Eszköz név', align: 'left', headerAlign: 'left', flex: 1, minWidth: 360,},
                {field: 'appVersion', headerName: 'App verzió', align: 'center', headerAlign: 'center', width: 140,},
                {field: 'buildNumber', headerName: 'Build szám', align: 'center', headerAlign: 'center', width: 140,},
                {field: 'lastLogIn', headerName: 'Ultosó bejelentkezés', align: 'center', headerAlign: 'center', width: 200,},
                {field: 'delete', headerName: ' ', headerAlign: 'right', align: 'right', width: 60, renderCell: this.renderAction},],
            rows: [],
            connectedTerminals: [],
        };
    }

    componentDidMount() {
        if (this.props.location.shopId) {
            axiosCommon.get(`${BASE_URL}/api/admin/shops/${this.props.location.shopId}`)
                .then(response => {
                    let {shop, fiscalSolution, payTerminalSolution} = this.state

                    if(response.data.fiscalPrinterConnection == null){
                        response.data.fiscalPrinterConnection = {fiscalPrinter: null, isFiscalPrinterAllowed: false,}
                    }
                    shop = response.data

                    if(shop.cashRegisterConnection.connectionType !== "NO_CONNECTION"){
                        fiscalSolution = "ONLINE_CASHIER"
                    } else if(shop.fiscalPrinterConnection.fiscalPrinter != null){
                        fiscalSolution = "FISCAL_PRINTER"
                    } else {
                        fiscalSolution = "NO_SOLUTION"
                    }

                    payTerminalSolution = shop.payTerminalConnection.payTerminalType || "NO_SOLUTION"

                    this.setState({ shop, fiscalSolution, payTerminalSolution })
                })
                .catch(error => {
                    this.setState({error});
                });

            axiosCommon.get(`${BASE_URL}/api/shopterminal/${this.props.location.shopId}`)
            .then(response => {
                const connectedTerminals = response.data
                const rows = connectedTerminals.map((e, i) => {
                    return {
                        id: e.id,
                        device: e.device,
                        appVersion: e.appVersion,
                        buildNumber: e.buildNumber,
                        lastLogIn: moment(e.lastLogIn).format("YYYY-MM-DD HH:mm:ss"),
                        delete: undefined,
                    }
                })
                this.setState({ rows , connectedTerminals})
            }).catch(error => {
                this.setState({error});
            })

        }
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {shop} = this.state;
        shop.ntakData.certFile = null
        shop.ntakData.keyFile = null

        if(shop.terminalsConnection.allowedTerminalNumber < this.state.connectedTerminals.length){
            this.setState({error: "A mentés nem lehetséges! A csatlakoztatott terminálok száma meghaladja az engedélyezett terminálok számát.", isSubmitInProgress: false});
            return
        }

        axiosCommon.put(`${BASE_URL}/api/admin/shops`, shop)
        .then(response => {
            this.goBack();
        })
        .catch(error => {
            this.setState({error, isSubmitInProgress: false});
        });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (event) => {
        const { shop } = this.state;
        shop[event.target.name] = event.target.value;
        this.setState({ shop });
    };

    handleCashRegisterChange = (event) => {
        const { cashRegisterConnection } = this.state.shop;
        cashRegisterConnection[event.target.name] = event.target.value;
        if(event.target.name == "connectionType"){
            cashRegisterConnection.cashRegister = null;
            cashRegisterConnection.printOnReceipt = false;
            cashRegisterConnection.sendDataWithNames = false;
        }
        if(event.target.name === "cashRegister" && cashRegisterConnection.cashRegister != null){
            if( cashRegisterConnection.cashRegister.includes("MICRA") ||
                cashRegisterConnection.cashRegister.includes("EURO_150TE") )
                cashRegisterConnection.sendDataWithNames = false
            else cashRegisterConnection.sendDataWithNames = true

            cashRegisterConnection.separateServiceFee = false;
        }
        this.setState({ cashRegisterConnection });
    };

    handleFiscalPrinterChange = (event) => {
        const { fiscalPrinterConnection } = this.state.shop;
        fiscalPrinterConnection[event.target.name] = event.target.value;
        this.setState({ fiscalPrinterConnection });
    };

    handleFiscalSolutionChange = (event) => {
        let { fiscalSolution } = this.state
        const { cashRegisterConnection, fiscalPrinterConnection } = this.state.shop;
        fiscalSolution = event.target.value
        cashRegisterConnection.connectionType = fiscalSolution === "ONLINE_CASHIER" ? "USB" : "NO_CONNECTION"
        cashRegisterConnection.cashRegister = null;
        cashRegisterConnection.printOnReceipt = false;
        cashRegisterConnection.sendDataWithNames = false;
        fiscalPrinterConnection.fiscalPrinter = null
        this.setState({ fiscalSolution, cashRegisterConnection, fiscalPrinterConnection });
    };

    handlePayTerminalChange = (event) => {
        const { payTerminalConnection } = this.state.shop
        let { payTerminalSolution } = this.state
        payTerminalSolution = event.target.value
        payTerminalConnection.payTerminalType = payTerminalSolution !== "NO_SOLUTION" ? payTerminalSolution : null
        this.setState({ payTerminalSolution, payTerminalConnection });
    };

    handleBillingChange = (event) => {
        const { billingConnection } = this.state.shop;
        billingConnection[event.target.name] = event.target.value;
        if(billingConnection.billingClient == "NO_CLIENT"){
            billingConnection.apiKey = null;
            billingConnection.defaultBillingBlockId = null;
            billingConnection.defaultBankAccountId = null;
            billingConnection.defaultInvoicePrefix = null;
        }
        this.setState({ billingConnection });
    };

    getCashregisterOptions(){
        switch(this.state.shop.cashRegisterConnection.connectionType){
            case "BLUETOOTH":
                return BluetoothCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            case "USB":
                return UsbCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            case "QR_CODE":
                return QrCodeCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            default:
                return undefined
        }
    }

    renderAction = (params) => {
        return <IconButton aria-label="delete" onClick={() => this.deleteTerminalRegistration(params.id)}>
            <DeleteIcon />
        </IconButton>
    }

    deleteTerminalRegistration = (id) => {
        axiosCommon.delete(`${BASE_URL}/api/shopterminal/${id}`)
        .then(response => {
            let { connectedTerminals } = this.state

            connectedTerminals = connectedTerminals.filter(item => item.id != id)
            const rows = connectedTerminals.map((e, i) => {
                return {
                    id: e.id,
                    device: e.device,
                    appVersion: e.appVersion,
                    buildNumber: e.buildNumber,
                    lastLogIn: moment(e.lastLogIn).format("YYYY-MM-DD HH:mm:ss"),
                    delete: undefined,
                }
            });

            this.setState({ connectedTerminals, rows });
        })
        .catch(error => {
            this.setState({error})
        });
    }

    renderCashregisterConnection = () => {
        return <>
            <Widget>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Csatlakozási mód"
                        variant="outlined"
                        onChange={this.handleCashRegisterChange}
                        name="connectionType"
                        fullWidth
                        value={this.state.shop.cashRegisterConnection.connectionType}
                        InputLabelProps={{ shrink: true }}
                    >
                        {
                            CashRegisterConnectionType.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                        }
                    </TextValidator>
                </div>
                <div className="m-8"/>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Pénztárgép típusa"
                        variant="outlined"
                        onChange={this.handleCashRegisterChange}
                        name="cashRegister"
                        fullWidth
                        value={this.state.shop.cashRegisterConnection.cashRegister || ""}
                        required
                        validators={['required']}
                        errorMessages={['A mező kitöltése kötelező']}
                    >
                        {this.getCashregisterOptions()}
                    </TextValidator>
                </div>
            </Widget>
            {this.state.shop.cashRegisterConnection.cashRegister == null
                ? undefined
                : <>
                    {this.state.shop.cashRegisterConnection.connectionType == "QR_CODE"
                        ?<>
                            <Tooltip title="A pénztárgép vezérléséhez generált QR kód megjelenik a fizetési összesítőn">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked
                                            onChange={(event) => {
                                                const { cashRegisterConnection } = this.state.shop;
                                                cashRegisterConnection.printOnReceipt = event.target.checked;
                                                this.setState({ cashRegisterConnection });
                                            }}
                                            name="qrCodeInTerminal"
                                            color="primary"
                                            disabled
                                        />
                                    }
                                    label="QR kód kijelzése a terminálon"
                                />
                            </Tooltip>
                            <Tooltip title="Amennyiben bepipálod a pénztárgép vezérléséhez generált QR kód a blokkon is szerepelni fog.">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.shop.cashRegisterConnection.printOnReceipt}
                                            onChange={(event) => {
                                                const { cashRegisterConnection } = this.state.shop;
                                                cashRegisterConnection.printOnReceipt = event.target.checked;
                                                this.setState({ cashRegisterConnection });
                                            }}
                                            name="printQRCode"
                                            color="primary"
                                        />
                                    }
                                    label="QR kód blokkra nyomtatása"
                                />
                            </Tooltip>
                        </>
                        : undefined
                    }
                    <Tooltip title="Amennyiben bepipálod a pénztárgépnek terméknevekkel együtt küldődnek el az eladási adatok.">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.shop.cashRegisterConnection.sendDataWithNames}
                                    onChange={(event) => {
                                        const { cashRegisterConnection } = this.state.shop;
                                        cashRegisterConnection.sendDataWithNames = event.target.checked;
                                        this.setState({ cashRegisterConnection });
                                    }}
                                    name="diffOnly"
                                    color="primary"
                                    disabled={
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("SAM4S") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("CASHCUBE") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("MICRA") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("EURO_150TE")}
                                />
                            }
                            label="Tételes adatküldés a pénztárgépnek"
                        />
                    </Tooltip>
                    <Tooltip title="Amennyiben bepipálod a pénztárgépen külön ÁFA gyűjtőre lesz küldve a szervizdíj.">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.shop.cashRegisterConnection.separateServiceFee}
                                    onChange={(event) => {
                                        const { cashRegisterConnection } = this.state.shop;
                                        cashRegisterConnection.separateServiceFee = event.target.checked;
                                        this.setState({ cashRegisterConnection });
                                    }}
                                    name="separateServiceFee"
                                    color="primary"
                                    disabled={
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("SAM4S")}
                                />
                            }
                            label="Külön gyűjtő a szervizdíjnak"
                        />
                    </Tooltip>
                </>
            }
        </>
    }

    renderFiscalPrinterConnection = () => {
        return <>
            <Widget>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Adóügyi nyomtató típusa"
                        variant="outlined"
                        onChange={this.handleFiscalPrinterChange}
                        name="fiscalPrinter"
                        fullWidth
                        value={this.state.shop.fiscalPrinterConnection.fiscalPrinter || ""}
                        required
                        validators={['required']}
                        errorMessages={['A mező kitöltése kötelező']}
                    >
                        {
                            FiscalPrinters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                        }
                    </TextValidator>
                </div>
                <div className="m-8"/>
                <div className="flex-1"/>
            </Widget>
        </>
    }

    getBillingApiInputLabel = () => {
        switch (this.state.shop.billingConnection.billingClient) {
            case "CLIENT_BILLINGO":
                return "V3 API kulcs"
            case "CLIENT_SZAMLAZZ":
            default:
                return "API kulcs"
        }
    }

    renderEdit = () => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.shopId ? "Üzlet szerkesztése" : "Új üzlet"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.shop.name || ""}
                                required
                                disabled
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Állapot"
                                variant="outlined"
                                onChange={(event) => {
                                    const { shop } = this.state;
                                    shop.enabled = event.target.value === 'true';
                                    this.setState({ shop });
                                }}
                                fullWidth
                                value={this.state.shop.enabled === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Aktív</MenuItem>
                                <MenuItem key='0' value='false'>Szünetel</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Kiegészítő csomagok">
                    <FormControl component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.cashRegisterConnection.isCashRegisterAllowed || false}
                                        onChange={(event) => {
                                            const { cashRegisterConnection } = this.state.shop;
                                            let { fiscalSolution } = this.state;
                                            cashRegisterConnection.isCashRegisterAllowed = event.target.checked
                                            if(cashRegisterConnection.isCashRegisterAllowed === false){
                                                fiscalSolution = "NO_SOLUTION"
                                                cashRegisterConnection.connectionType = "NO_CONNECTION"
                                                cashRegisterConnection.cashRegister = null;
                                                cashRegisterConnection.printOnReceipt = false;
                                                cashRegisterConnection.sendDataWithNames = false;
                                                cashRegisterConnection.separateServiceFee = false;
                                            }
                                            this.setState({ cashRegisterConnection, fiscalSolution });
                                        }}
                                        color="primary"
                                    />}
                                label="Pénztárgép csomag"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.fiscalPrinterConnection.isFiscalPrinterAllowed || false}
                                        onChange={(event) => {
                                            const { fiscalPrinterConnection } = this.state.shop;
                                            let { fiscalSolution } = this.state;
                                            fiscalPrinterConnection.isFiscalPrinterAllowed = event.target.checked
                                            if(fiscalPrinterConnection.isFiscalPrinterAllowed == false){
                                                fiscalPrinterConnection.fiscalPrinter = null
                                                fiscalSolution = "NO_SOLUTION"
                                            }
                                            this.setState({ fiscalPrinterConnection, fiscalSolution });
                                        }}
                                        color="primary"
                                    />}
                                label="Adóügyi nyomtató csomag"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.payTerminalConnection.isPayTerminalAllowed || false}
                                        onChange={(event) => {
                                            const { payTerminalConnection } = this.state.shop;
                                            let { payTerminalSolution } = this.state
                                            payTerminalConnection.isPayTerminalAllowed = event.target.checked
                                            if(payTerminalConnection.isPayTerminalAllowed === false){
                                                payTerminalConnection.payTerminalType = null
                                                payTerminalSolution = "NO_SOLUTION"
                                            }
                                            this.setState({ payTerminalConnection, payTerminalSolution });
                                        }}
                                        color="primary"
                                    />}
                                label="Bankkártya terminál csomag"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.billingConnection.isBillingAllowed || false}
                                        onChange={(event) => {
                                            const { billingConnection } = this.state.shop;
                                            billingConnection.isBillingAllowed = event.target.checked
                                            if(billingConnection.isBillingAllowed === false) {
                                                billingConnection.billingClient ="NO_CLIENT"
                                                billingConnection.apiKey = null;
                                                billingConnection.defaultBillingBlockId = null;
                                                billingConnection.defaultBankAccountId = null;
                                            }
                                            this.setState({ billingConnection });
                                        }}
                                        color="primary"
                                    />}
                                label="Áfa-s számla csomag"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.scaleConnection.isScaleAllowed || false}
                                        onChange={(event) => {
                                            const { scaleConnection } = this.state.shop;
                                            scaleConnection.isScaleAllowed = event.target.checked
                                            this.setState({ scaleConnection });
                                        }}
                                        color="primary"
                                    />}
                                label="Mérleg csomag"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.nfcConnection.isNfcAllowed || false}
                                        onChange={(event) => {
                                            const { nfcConnection } = this.state.shop;
                                            nfcConnection.isNfcAllowed = event.target.checked
                                            this.setState({ nfcConnection });
                                        }}
                                        color="primary"
                                    />}
                                label="NFC csomag"
                            />

                        </FormGroup>
                    </FormControl>
                    <Widget>
                        <div className="flex-1">
                            <Typography style={{marginBottom: 8}}>Engedélyezett terminálok száma:</Typography>
                            <TextValidator
                                label="Darabszám"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const { terminalsConnection } = this.state.shop
                                    terminalsConnection.allowedTerminalNumber = event.target.value
                                    this.setState({ terminalsConnection });
                                }}
                                name="name"
                                value={this.state.shop.terminalsConnection.allowedTerminalNumber}
                                required
                                validators={['required', 'isNumber', 'minNumber:1', 'maxNumber:99']}
                                errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'A megadott érték nem lehet kisebb 1-nél', 'A megadott érték nem lehet nagyobb 99-nél']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Adóügyi egység csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Adóügyi egység"
                                variant="outlined"
                                onChange={this.handleFiscalSolutionChange}
                                name="fiscalSolutionType"
                                fullWidth
                                value={this.state.fiscalSolution}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    FiscalSolutions.filter(it =>
                                        (it.value === "FISCAL_PRINTER" && this.state.shop.fiscalPrinterConnection.isFiscalPrinterAllowed === true) ||
                                        (it.value === "ONLINE_CASHIER" && this.state.shop.cashRegisterConnection.isCashRegisterAllowed === true) ||
                                        (it.value === "NO_SOLUTION")
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                    {{
                        "NO_SOLUTION": undefined,
                        "ONLINE_CASHIER": this.renderCashregisterConnection(),
                        "FISCAL_PRINTER": this.renderFiscalPrinterConnection(),
                    }[this.state.fiscalSolution]}
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Kártya terminal csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Kártya terminál típusa"
                                variant="outlined"
                                onChange={this.handlePayTerminalChange}
                                name="payTerminalType"
                                fullWidth
                                value={this.state.payTerminalSolution}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    PayTerminals.filter(it =>
                                        (it.value !== 'NO_SOLUTION' && this.state.shop.payTerminalConnection.isPayTerminalAllowed === true) ||
                                        (it.value === 'NO_SOLUTION')
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Számlázó program csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Számlázó program"
                                variant="outlined"
                                onChange={this.handleBillingChange}
                                name="billingClient"
                                fullWidth
                                value={this.state.shop.billingConnection.billingClient}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    BillingClients.filter(it =>
                                        (it.value !== 'NO_CLIENT' && this.state.shop.billingConnection.isBillingAllowed === true) ||
                                        (it.value === 'NO_CLIENT')
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            {this.state.shop.billingConnection.billingClient != "NO_CLIENT"
                                ?<TextValidator
                                    label={this.getBillingApiInputLabel()}
                                    variant="outlined"
                                    onChange={this.handleBillingChange}
                                    name="apiKey"
                                    fullWidth
                                    value={this.state.shop.billingConnection.apiKey || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']} />
                                :""
                            }
                        </div>
                    </Widget>
                    {this.state.shop.billingConnection.billingClient === "CLIENT_BILLINGO"
                        ?<>
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Alapértelmezett bizonylattömb V3 API ID"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultBillingBlockId"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultBillingBlockId || ""}
                                        required
                                        validators={['required', 'isNumber']}
                                        errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni']} />
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Alapértelmezett bankszámlaszám V3 API ID"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultBankAccountId"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultBankAccountId || ""}
                                        required
                                        validators={['required', 'isNumber']}
                                        errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni']} />
                                </div>
                            </Widget>
                        </>
                        :""
                    }
                    {this.state.shop.billingConnection.billingClient === "CLIENT_SZAMLAZZ"
                        ?<>
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Számlaszám előtag"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultInvoicePrefix"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultInvoicePrefix || ""}
                                        required
                                        validators={['required']}
                                        errorMessages={['A mező kitöltése kötelező']} />
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1"/>
                            </Widget>
                        </>
                        :""
                    }
                </Card>

                <div style={{ marginTop: 16}}/>

                <Paper>
                    <Typography variant="h5" id="tableTitle" component="div" style={{paddingTop: 10, paddingBottom: 10, paddingLeft: 24, color: "#00AFCF"}}>
                        Csalakoztatott terminálok
                    </Typography>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        loading={this.state.isLoading}
                        components={{
                            Toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <GridToolbarExport/>
                                    </GridToolbarContainer>
                                );
                            },
                            NoRowsOverlay: NoData,
                        }}
                    />
                </Paper>

                <div style={{ marginTop: 16}}/>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
