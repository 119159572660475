import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import NavHeader from "../../../components/NavHeader";
import Footer from "../../../components/Footer";
import {
    Checkbox, FormControl,
    FormControlLabel, FormGroup,
    IconButton,
    MenuItem, Radio, RadioGroup, TextField,
    Toolbar, Tooltip,
    Typography
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import {DropzoneArea} from "material-ui-dropzone";
import SaveCancelButtons from "../../../components/SaveCancelButtons";
import {
    FiscalSolutions,
    FiscalPrinters,
    BluetoothCashRegisters,
    CashRegisterConnectionType,
    UsbCashRegisters,
    QrCodeCashRegisters, PayTerminals
} from "../../../util/FiscalSolutions";
import {BillingClients} from "../../../util/BillingClients";
import { ntakDataSending } from "../../../util/NTAK";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

export default class EditShop extends React.Component {
    static PAYMENT_TYPES = [
        {label: "Készpénz", value: "CASH"},
        // {label: "Euro", value: "EURO"},
        {label: "Bankkártya", value: "CARD"},
        {label: "SZÉP Kártya", value: "OTP_SZEP"},
        {label: "Kupon", value: "COUPON"},
        {label: "Átutalás", value: "TRANSFER"},
        {label: "Egyéb", value: "OTHER"},
        {label: "Wolt", value: "WOLT"},
        {label: "Foodora", value: "FOOD_PANDA"},
        {label: "Falatozz.hu", value: "FALATOZZ"},
        {label: "Festipay", value: "FESTIPAY"},
        {label: "Munch", value: "MUNCH"},
    ]

    constructor(props) {
        super(props);
        this.state = {
            shop: {
                name: null,
                address: {
                    street: null,
                    city: null,
                    zipCode: null,
                },
                receiptData: {
                    companyName: null,
                    taxNumber: null,
                    email: null,
                    homepage: null,
                    header: null,
                    footer: null,
                    imageId: null,
                },
                cashRegisterConnection: {
                    isCashRegisterAllowed: false,
                    connectionType: "NO_CONNECTION",
                    cashRegister: null,
                    printOnReceipt: false,
                    sendDataWithNames: false,
                    separateServiceFee: false,
                },
                fiscalPrinterConnection: {
                    fiscalPrinter: null,
                    isFiscalPrinterAllowed: false,
                },
                billingConnection: {
                    isBillingAllowed: false,
                    billingClient: "NO_CLIENT",
                    apiKey: null,
                    defaultBillingBlockId: null,
                    defaultBankAccountId: null,
                    defaultInvoicePrefix: null,
                },
                payTerminalConnection: {
                    payTerminalType: null,
                    isPayTerminalAllowed: false,
                },
                scaleConnection: {
                    isScaleAllowed: false,
                },
                terminalsConnection: {
                    allowedTerminalNumber: 1,
                },
                nfcConnection: {
                    isNfcAllowed: false,
                },
                ntakData: {
                    ntakDataSendingEnabled: false,
                    ntakTaxNumber: null,
                    ntakRegistrationNumber: null,
                    certFile: null,
                    keyFile: null,
                },
                deliveryPersonNames: [],
                shopPaymentTypes: [],
                description: null,
                turnAroundTime: "04:00",
                serviceFee: 0,
                builtInServiceFee: false,
                serviceFeePaymentTypes: [],
                eurHufPrice: null,
                enabled: true,
                enabledTip: false,
                printEur: false,
            },
            fiscalSolution: "NO_SOLUTION",
            payTerminalSolution: "NO_SOLUTION",
            isSubmitInProgress: false,
            enabledPaymentTypes: [],
            serviceFeePaymentTypes: [],
        };
    }

    componentDidMount() {
        if (this.props.location.shopId) {
            axiosCommon.get(`${BASE_URL}/api/shops/${this.props.location.shopId}`)
                .then(response => {
                    const {enabledPaymentTypes, serviceFeePaymentTypes} = this.state;
                    let {shop, fiscalSolution, payTerminalSolution} = this.state

                    response.data.shopPaymentTypes.forEach(p => enabledPaymentTypes[p] = true)
                    response.data.serviceFeePaymentTypes.forEach(p => serviceFeePaymentTypes.push(EditShop.PAYMENT_TYPES.filter((item) => item.value == p)[0]))
                    if(response.data.fiscalPrinterConnection == null){
                        response.data.fiscalPrinterConnection = {fiscalPrinter: null, isFiscalPrinterAllowed: false,}
                    }
                    shop = response.data

                    if(shop.cashRegisterConnection.connectionType !== "NO_CONNECTION"){
                        fiscalSolution = "ONLINE_CASHIER"
                    } else if(shop.fiscalPrinterConnection.fiscalPrinter != null){
                        fiscalSolution = "FISCAL_PRINTER"
                    } else {
                        fiscalSolution = "NO_SOLUTION"
                    }

                    payTerminalSolution = shop.payTerminalConnection.payTerminalType || "NO_SOLUTION"

                    this.setState({ shop, enabledPaymentTypes, serviceFeePaymentTypes, fiscalSolution, payTerminalSolution })
                })
                .catch(error => {
                    this.setState({error});
                });
        }else{
            const {enabledPaymentTypes} = this.state;
            const {serviceFeePaymentTypes} = this.state;
            enabledPaymentTypes.CASH = true
            enabledPaymentTypes.CARD = true
            Object.keys(enabledPaymentTypes).forEach((type) =>
                serviceFeePaymentTypes.push(EditShop.PAYMENT_TYPES.filter((item) => item.value === type)[0]))
            this.setState({ enabledPaymentTypes })
        }
    }

    onSubmit = () => {
        this.setState({isSubmitInProgress: true});

        const {shop} = this.state;

        if (this.state.encodedImage) {
            shop.receiptData.imageData = this.state.encodedImage;
            shop.receiptData.imageContentType = this.state.imageType;
        }

        shop.shopPaymentTypes = []
        EditShop.PAYMENT_TYPES.forEach(type => {
            if (this.state.enabledPaymentTypes[type.value]) {
               shop.shopPaymentTypes.push(type.value)
            }
        })

        shop.serviceFeePaymentTypes = []
        this.state.serviceFeePaymentTypes.forEach((item) => shop.serviceFeePaymentTypes.push(item.value))

        const queryParams = {}
        if (this.state.deleteImage) {
            queryParams.deleteImage = true
        }

        axiosCommon({
            method: this.props.location.shopId ? "put" : "post",
            url: `${BASE_URL}/api/shops`,
            data: shop,
            params: queryParams
        })
            .then(response => {
                this.goBack();
            })
            .catch(error => {
                this.setState({error, isSubmitInProgress: false});
            });
    };

    goBack = () => {
        this.props.history.goBack();
    };

    handleChange = (event) => {
        const { shop } = this.state;
        shop[event.target.name] = event.target.value;
        this.setState({ shop });
    };

    handleAddressChange = (event) => {
        const { address } = this.state.shop;
        address[event.target.name] = event.target.value;
        this.setState({ address });
    };

    handleReceiptChange = (event) => {
        const { receiptData } = this.state.shop;
        receiptData[event.target.name] = event.target.value;
        this.setState({ receiptData });
    };

    handleCashRegisterChange = (event) => {
        const { cashRegisterConnection } = this.state.shop;
        cashRegisterConnection[event.target.name] = event.target.value;
        if(event.target.name == "connectionType"){
            cashRegisterConnection.cashRegister = null;
            cashRegisterConnection.printOnReceipt = false;
            cashRegisterConnection.sendDataWithNames = false;
        }
        if(event.target.name === "cashRegister" && cashRegisterConnection.cashRegister != null){
            if( cashRegisterConnection.cashRegister.includes("MICRA") ||
                cashRegisterConnection.cashRegister.includes("EURO_150TE") )
                cashRegisterConnection.sendDataWithNames = false
            else cashRegisterConnection.sendDataWithNames = true

            cashRegisterConnection.separateServiceFee = false;
        }
        this.setState({ cashRegisterConnection });
    };

    handleFiscalPrinterChange = (event) => {
        const { fiscalPrinterConnection } = this.state.shop;
        fiscalPrinterConnection[event.target.name] = event.target.value;
        this.setState({ fiscalPrinterConnection });
    };

    handleFiscalSolutionChange = (event) => {
        let { fiscalSolution } = this.state
        const { cashRegisterConnection, fiscalPrinterConnection } = this.state.shop;
        fiscalSolution = event.target.value
        cashRegisterConnection.connectionType = fiscalSolution === "ONLINE_CASHIER" ? "USB" : "NO_CONNECTION"
        cashRegisterConnection.cashRegister = null;
        cashRegisterConnection.printOnReceipt = false;
        cashRegisterConnection.sendDataWithNames = false;
        fiscalPrinterConnection.fiscalPrinter = null
        this.setState({ fiscalSolution, cashRegisterConnection, fiscalPrinterConnection });
    };

    handlePayTerminalChange = (event) => {
        const { payTerminalConnection } = this.state.shop
        let { payTerminalSolution } = this.state
        payTerminalSolution = event.target.value
        payTerminalConnection.payTerminalType = payTerminalSolution !== "NO_SOLUTION" ? payTerminalSolution : null
        this.setState({ payTerminalSolution, payTerminalConnection });
    };

    handleBillingChange = (event) => {
        const { billingConnection } = this.state.shop;
        billingConnection[event.target.name] = event.target.value;
        if(billingConnection.billingClient == "NO_CLIENT"){
            billingConnection.apiKey = null;
            billingConnection.defaultBillingBlockId = null;
            billingConnection.defaultBankAccountId = null;
            billingConnection.defaultInvoicePrefix = null;
        }
        this.setState({ billingConnection });
    };

    handleNTAKDataChange = (event) => {
        const { ntakData } = this.state.shop;
        ntakData[event.target.name] = event.target.value;
        this.setState({ ntakData });
    };


    onSelectImage = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                this.setState({
                    encodedImage: btoa(reader.result),
                    imageType: loadedFiles[0].type,
                    deleteImage: false
                })
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteImage = () => {
        const {receiptData} = this.state.shop;
        delete receiptData.imageId
        this.setState({
            receiptData,
            deleteImage: true
        })
    };

    onSelectCertFile = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const { ntakData } = this.state.shop;
                ntakData.certFile = {
                    name: loadedFiles[0].name,
                    type: loadedFiles[0].type,
                    data: btoa(reader.result)
                }
                this.setState({ ntakData });
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteCertFile = () => {
        const { ntakData } = this.state.shop;
        ntakData.certFile = null
        this.setState({ ntakData });
    }

    onSelectKeyFile = (loadedFiles) => {
        if (loadedFiles.length > 0) {
            const reader = new FileReader();
            reader.onloadend = () => {
                const { ntakData } = this.state.shop;
                ntakData.keyFile = {
                    name: loadedFiles[0].name,
                    type: loadedFiles[0].type,
                    data: btoa(reader.result)
                }
                this.setState({ ntakData });
            };
            reader.readAsBinaryString(loadedFiles[0]);
        } else {
            this.setState({
                encodedImage: null
            })
        }
    };

    onDeleteKeyFile = () => {
        const { ntakData } = this.state.shop;
        ntakData.keyFile = null
        this.setState({ ntakData });
    }

    handleEnabledTipChange = (event) => {
        const { shop } = this.state;
        shop.enabledTip = event.target.value === 'true';
        this.setState({ shop });
    }

    onChangeServiceFeePaymentTypes = (event, value) => {
        this.setState({ serviceFeePaymentTypes: value })
    };

    getFileObject = (file) => new File([], file.name, {type: file.type})

    onChangeServiceFeeType = event => {
        const { shop } = this.state;
        shop.builtInServiceFee = event.target.value === 'true'
        this.setState({ shop });
    }

    getCashregisterOptions(){
        switch(this.state.shop.cashRegisterConnection.connectionType){
            case "BLUETOOTH":
                return BluetoothCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            case "USB":
                return UsbCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            case "QR_CODE":
                return QrCodeCashRegisters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
            default:
                return undefined
        }
    }

    renderCashregisterConnection = () => {
        return <>
            <Widget>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Csatlakozási mód"
                        variant="outlined"
                        onChange={this.handleCashRegisterChange}
                        name="connectionType"
                        fullWidth
                        value={this.state.shop.cashRegisterConnection.connectionType}
                        InputLabelProps={{ shrink: true }}
                    >
                        {
                            CashRegisterConnectionType.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                        }
                    </TextValidator>
                </div>
                <div className="m-8"/>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Pénztárgép típusa"
                        variant="outlined"
                        onChange={this.handleCashRegisterChange}
                        name="cashRegister"
                        fullWidth
                        value={this.state.shop.cashRegisterConnection.cashRegister || ""}
                        required
                        validators={['required']}
                        errorMessages={['A mező kitöltése kötelező']}
                    >
                        {this.getCashregisterOptions()}
                    </TextValidator>
                </div>
            </Widget>
            {this.state.shop.cashRegisterConnection.cashRegister == null
                ? undefined
                : <>
                    {this.state.shop.cashRegisterConnection.connectionType == "QR_CODE"
                        ?<>
                            <Tooltip title="A pénztárgép vezérléséhez generált QR kód megjelenik a fizetési összesítőn">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked
                                            onChange={(event) => {
                                                const { cashRegisterConnection } = this.state.shop;
                                                cashRegisterConnection.printOnReceipt = event.target.checked;
                                                this.setState({ cashRegisterConnection });
                                            }}
                                            name="qrCodeInTerminal"
                                            color="primary"
                                            disabled
                                        />
                                    }
                                    label="QR kód kijelzése a terminálon"
                                />
                            </Tooltip>
                            <Tooltip title="Amennyiben bepipálod a pénztárgép vezérléséhez generált QR kód a blokkon is szerepelni fog.">
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={this.state.shop.cashRegisterConnection.printOnReceipt}
                                            onChange={(event) => {
                                                const { cashRegisterConnection } = this.state.shop;
                                                cashRegisterConnection.printOnReceipt = event.target.checked;
                                                this.setState({ cashRegisterConnection });
                                            }}
                                            name="printQRCode"
                                            color="primary"
                                        />
                                    }
                                    label="QR kód blokkra nyomtatása"
                                />
                            </Tooltip>
                        </>
                        : undefined
                    }
                    <Tooltip title="Amennyiben bepipálod a pénztárgépnek terméknevekkel együtt küldődnek el az eladási adatok.">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.shop.cashRegisterConnection.sendDataWithNames}
                                    onChange={(event) => {
                                        const { cashRegisterConnection } = this.state.shop;
                                        cashRegisterConnection.sendDataWithNames = event.target.checked;
                                        this.setState({ cashRegisterConnection });
                                    }}
                                    name="diffOnly"
                                    color="primary"
                                    disabled={
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("SAM4S") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("CASHCUBE") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("MICRA") ||
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("EURO_150TE")}
                                />
                            }
                            label="Tételes adatküldés a pénztárgépnek"
                        />
                    </Tooltip>
                    <Tooltip title="Amennyiben bepipálod a pénztárgépen külön ÁFA gyűjtőre lesz küldve a szervizdíj.">
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={this.state.shop.cashRegisterConnection.separateServiceFee}
                                    onChange={(event) => {
                                        const { cashRegisterConnection } = this.state.shop;
                                        cashRegisterConnection.separateServiceFee = event.target.checked;
                                        this.setState({ cashRegisterConnection });
                                    }}
                                    name="separateServiceFee"
                                    color="primary"
                                    disabled={
                                        this.state.shop.cashRegisterConnection.cashRegister.includes("SAM4S")}
                                />
                            }
                            label="Külön gyűjtő a szervizdíjnak"
                        />
                    </Tooltip>
                </>
            }
        </>
    }

    renderFiscalPrinterConnection = () => {
        return <>
            <Widget>
                <div className="flex-1">
                    <TextValidator
                        select
                        label="Adóügyi nyomtató típusa"
                        variant="outlined"
                        onChange={this.handleFiscalPrinterChange}
                        name="fiscalPrinter"
                        fullWidth
                        value={this.state.shop.fiscalPrinterConnection.fiscalPrinter || ""}
                        required
                        validators={['required']}
                        errorMessages={['A mező kitöltése kötelező']}
                    >
                        {
                            FiscalPrinters.map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                        }
                    </TextValidator>
                </div>
                <div className="m-8"/>
                <div className="flex-1"/>
            </Widget>
        </>
    }

    getBillingApiInputLabel = () => {
        switch (this.state.shop.billingConnection.billingClient) {
            case "CLIENT_BILLINGO":
                return "V3 API kulcs"
            case "CLIENT_SZAMLAZZ":
            default:
                return "API kulcs"
        }
    }

    renderEdit = () => {
        return <>
            <Toolbar className="mb-16 align-center justify-space-between">
                <Typography variant="h5" id="tableTitle" component="div" >
                    {this.props.location.shopId ? "Üzlet szerkesztése" : "Új üzlet"}
                </Typography>
                <IconButton onClick={this.goBack}>
                    <ClearIcon fontSize="large"/>
                </IconButton>
            </Toolbar>

            <ValidatorForm onSubmit={this.onSubmit}>
                <Card title="Általános adatok">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Név"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="name"
                                value={this.state.shop.name || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Állapot"
                                variant="outlined"
                                disabled
                                fullWidth
                                value={this.state.shop.enabled === true ? "Aktív" : "Szünetel"}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Euró árfolyam"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleChange}
                                name="eurHufPrice"
                                value={this.state.shop.eurHufPrice || ""}
                                required
                                validators={['required', 'isNumber', 'minNumber: 1']}
                                errorMessages={['A mező kitöltése kötelező', 'A megadott érték nem szám', 'Az értéknek nagyobbnak kell lennie 0-nál']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Napi zárás"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="turnAroundTime"
                                required
                                fullWidth
                                value={this.state.shop.turnAroundTime || ""}
                                InputLabelProps={{ shrink: true }}
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            >
                                <MenuItem key='00:00' value='00:00'>00:00</MenuItem>
                                <MenuItem key='01:00' value='01:00'>01:00</MenuItem>
                                <MenuItem key='02:00' value='02:00'>02:00</MenuItem>
                                <MenuItem key='03:00' value='03:00'>03:00</MenuItem>
                                <MenuItem key='04:00' value='04:00'>04:00</MenuItem>
                                <MenuItem key='05:00' value='05:00'>05:00</MenuItem>
                                <MenuItem key='06:00' value='06:00'>06:00</MenuItem>
                                <MenuItem key='07:00' value='07:00'>07:00</MenuItem>
                                <MenuItem key='08:00' value='08:00'>08:00</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Irányítószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="zipCode"
                                value={this.state.shop.address.zipCode || ""}
                                inputProps={{ maxLength: 10 }}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Város"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="city"
                                value={this.state.shop.address.city || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cím"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleAddressChange}
                                name="street"
                                value={this.state.shop.address.street || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Borravaló kezelés"
                                variant="outlined"
                                onChange={this.handleEnabledTipChange}
                                name="tip"
                                fullWidth
                                value={this.state.shop.enabledTip === true}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='1' value='true'>Igen</MenuItem>
                                <MenuItem key='0' value='false'>Nem</MenuItem>
                            </TextValidator>
                        </div>
                    </Widget>

                    <TextValidator
                        label="Leírás"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="description"
                        value={this.state.shop.description || ""}
                        inputProps={{ maxLength: 1024 }}
                        onChange={this.handleChange}
                    />
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Engedélyezett fizetési módok">
                    <Typography>Itt tudod megadni az üzletben engedélyezett fizetési módokat. (Legalább 1 kiválasztása kötelező)</Typography>
                    <FormControl component="fieldset">
                        <FormGroup>
                            {EditShop.PAYMENT_TYPES.map((type, i) =>
                              <FormControlLabel
                                key={i}
                                control={
                                    <Checkbox
                                      checked={this.state.enabledPaymentTypes[type.value] || false}
                                      onChange={(event) => {
                                          const {enabledPaymentTypes} = this.state
                                          let {serviceFeePaymentTypes} = this.state
                                          let numberOfTypes = 0
                                          EditShop.PAYMENT_TYPES.forEach(type => {
                                              if (enabledPaymentTypes[type.value]) numberOfTypes++
                                          })

                                          if(numberOfTypes == 1 && event.target.checked == false){
                                              //
                                          }else {
                                              enabledPaymentTypes[event.target.name] = event.target.checked

                                              serviceFeePaymentTypes = serviceFeePaymentTypes.filter((item) => item.value !== event.target.name)
                                              if(event.target.checked){
                                                  serviceFeePaymentTypes.push(EditShop.PAYMENT_TYPES.filter((item) => item.value == event.target.name)[0])
                                              }
                                              this.setState({ enabledPaymentTypes, serviceFeePaymentTypes });
                                          }
                                      }}
                                      color="primary"
                                      name={type.value} />}
                                label={type.label}
                              />
                            )}
                        </FormGroup>
                    </FormControl>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Szervizdíj kezelés">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Szervizdíj (%)"
                                variant="outlined"
                                fullWidth
                                onChange={(event) => {
                                    const {value} = event.target
                                    if (!String(value).match(/^([0-9]+([.,][0-9]*)?)?$/)) return

                                    const quantity = String(value).replace(",", ".");
                                    // Only 1 decimal places are allowed
                                    const decimalPlace = quantity.indexOf(".")
                                    if(decimalPlace != -1 && value.length - decimalPlace > 2) return;

                                    const { shop } = this.state;
                                    shop.serviceFee = quantity;
                                    if(quantity == 0.0) shop.builtInServiceFee = false
                                    this.setState({ shop });
                                }}
                                name="serviceFee"
                                value={this.state.shop.serviceFee === null ? "" : this.state.shop.serviceFee}
                                required
                                validators={['isFloat', 'minFloat:0.0', 'maxFloat:100.0']}
                                errorMessages={['Csak számot lehet megadni', 'A megadott érték nem lehet kisebb 0.0-nál', 'A megadott érték nem lehet nagyobb 100.0-nál']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            {this.state.shop.serviceFee > 0.0
                                ? <FormControl>
                                    <RadioGroup name="builtInServiceFee" value={String(this.state.shop.builtInServiceFee)} onChange={this.onChangeServiceFeeType}>
                                        <Tooltip title="A szervizdíj felszámítása a vásárlás végösszege alapján történik és ahhoz adódik hozzá. Kiszállítás és elviteles rendelés esetén a szervizdíj nem kerül felszámításra.">
                                            <FormControlLabel style={{marginTop: -10}} value="false" control={<Radio color="primary"/>} label="A szervizdíj a végösszegéhez adódik." />
                                        </Tooltip>
                                        <Tooltip title="A termékek ára már tartalmazza a szervizdíj mértékét. Ebben az esetben a szervizdíj felszámításra kerül kiszállítás és elviteles rendelés esetében is.">
                                            <FormControlLabel style={{marginTop: -10}} value="true" control={<Radio color="primary"/>} label="A szervizdíj a termékek árában már szerepel." />
                                        </Tooltip>
                                    </RadioGroup>
                                </FormControl>
                                : undefined
                            }
                        </div>
                    </Widget>

                    {this.state.shop.serviceFee > 0.0 && this.state.shop.builtInServiceFee === false
                        ?<Widget>
                            <div className="flex-1">
                                <Autocomplete
                                    multiple
                                    onChange={this.onChangeServiceFeePaymentTypes}
                                    name="serviceFeePaymentTypeSelector"
                                    fullWidth
                                    disableClearable
                                    disableCloseOnSelect
                                    noOptionsText="Nincs ilyen tétel"
                                    options={EditShop.PAYMENT_TYPES.filter((item) => this.state.enabledPaymentTypes[item.value] === true)}
                                    getOptionLabel={(option) => option === "" ? "" : option.label}
                                    getOptionSelected={(option, value) => option.value === value.value}
                                    value={this.state.serviceFeePaymentTypes || ""}
                                    renderOption={(option, { selected }) => (
                                        <>
                                            <Checkbox
                                                icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                                checkedIcon={<CheckBoxIcon fontSize="small" />}
                                                style={{ marginRight: 8 }}
                                                checked={selected}
                                                color="primary"
                                            />
                                            {option.label}
                                        </>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params} required={this.state.serviceFeePaymentTypes.length === 0} variant="outlined" label="Fizetési módok, ahol szervizdíjat alkalmazunk"  />
                                    )}
                                />
                            </div>
                            <div className="m-8"/>
                            <div className="flex-1"/>
                        </Widget>
                        : undefined
                    }

                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Adóügyi egység csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Adóügyi egység"
                                variant="outlined"
                                onChange={this.handleFiscalSolutionChange}
                                name="fiscalSolutionType"
                                fullWidth
                                value={this.state.fiscalSolution}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    FiscalSolutions.filter(it =>
                                        (it.value === "FISCAL_PRINTER" && this.state.shop.fiscalPrinterConnection.isFiscalPrinterAllowed === true) ||
                                        (it.value === "ONLINE_CASHIER" && this.state.shop.cashRegisterConnection.isCashRegisterAllowed === true) ||
                                        (it.value === "NO_SOLUTION")
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                    {{
                        "NO_SOLUTION": undefined,
                        "ONLINE_CASHIER": this.renderCashregisterConnection(),
                        "FISCAL_PRINTER": this.renderFiscalPrinterConnection(),
                    }[this.state.fiscalSolution]}
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Kártya terminal csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Kártya terminál típusa"
                                variant="outlined"
                                onChange={this.handlePayTerminalChange}
                                name="payTerminalType"
                                fullWidth
                                value={this.state.payTerminalSolution}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    PayTerminals.filter(it =>
                                        (it.value !== 'NO_SOLUTION' && this.state.shop.payTerminalConnection.isPayTerminalAllowed === true) ||
                                        (it.value === 'NO_SOLUTION')
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Számlázó program csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Számlázó program"
                                variant="outlined"
                                onChange={this.handleBillingChange}
                                name="billingClient"
                                fullWidth
                                value={this.state.shop.billingConnection.billingClient}
                                InputLabelProps={{ shrink: true }}
                            >
                                {
                                    BillingClients.filter(it =>
                                        (it.value !== 'NO_CLIENT' && this.state.shop.billingConnection.isBillingAllowed === true) ||
                                        (it.value === 'NO_CLIENT')
                                    ).map(type => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)
                                }
                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            {this.state.shop.billingConnection.billingClient != "NO_CLIENT"
                                ?<TextValidator
                                    label={this.getBillingApiInputLabel()}
                                    variant="outlined"
                                    onChange={this.handleBillingChange}
                                    name="apiKey"
                                    fullWidth
                                    value={this.state.shop.billingConnection.apiKey || ""}
                                    required
                                    validators={['required']}
                                    errorMessages={['A mező kitöltése kötelező']} />
                                :""
                            }
                        </div>
                    </Widget>
                    {this.state.shop.billingConnection.billingClient === "CLIENT_BILLINGO"
                        ?<>
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Alapértelmezett bizonylattömb V3 API ID"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultBillingBlockId"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultBillingBlockId || ""}
                                        required
                                        validators={['required', 'isNumber']}
                                        errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni']} />
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Alapértelmezett bankszámlaszám V3 API ID"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultBankAccountId"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultBankAccountId || ""}
                                        required
                                        validators={['required', 'isNumber']}
                                        errorMessages={['A mező kitöltése kötelező', 'Csak számot lehet megadni']} />
                                </div>
                            </Widget>
                        </>
                        :""
                    }
                    {this.state.shop.billingConnection.billingClient === "CLIENT_SZAMLAZZ"
                        ?<>
                            <Widget>
                                <div className="flex-1">
                                    <TextValidator
                                        label="Számlaszám előtag"
                                        variant="outlined"
                                        onChange={this.handleBillingChange}
                                        name="defaultInvoicePrefix"
                                        fullWidth
                                        value={this.state.shop.billingConnection.defaultInvoicePrefix || ""}
                                        required
                                        validators={['required']}
                                        errorMessages={['A mező kitöltése kötelező']} />
                                </div>
                                <div className="m-8"/>
                                <div className="flex-1"/>
                            </Widget>
                        </>
                        :""
                    }
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="NTAK adatszolgáltatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                select
                                label="Adatküldés az NTAK-nak"
                                variant="outlined"
                                onChange={(event) => {
                                    const { ntakData } = this.state.shop;
                                    ntakData.ntakDataSendingEnabled = event.target.value === 'true';
                                    this.setState({ ntakData });
                                }}
                                name="ntakDataSendingEnabled"
                                fullWidth
                                value={this.state.shop.ntakData.ntakDataSendingEnabled}
                                InputLabelProps={{ shrink: true }}
                            >
                                <MenuItem key='0' value='false'>Nincs</MenuItem>
                                {ntakDataSending === true
                                    ?<MenuItem key='1' value='true'>Igen</MenuItem>
                                    : undefined
                                }

                            </TextValidator>
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="NTAK regisztációs szám"
                                variant="outlined"
                                onChange={this.handleNTAKDataChange}
                                name="ntakRegistrationNumber"
                                fullWidth
                                value={this.state.shop.ntakData.ntakRegistrationNumber || ""}
                                required={this.state.shop.ntakData.ntakDataSendingEnabled}
                                validators={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['required', 'matchRegexp:^[a-zA-Z]{2}\\d{8}$']
                                    : ['matchRegexp:^[a-zA-Z]{2}\\d{8}$']
                                }
                                errorMessages={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['A mező kitöltése kötelező', 'A mező 2 nagybetűvel kezdődik amelyet 8 szám követ. pl.: XY12345678']
                                    : ['A mező 2 nagybetűvel kezdődik amelyet 8 szám követ. pl.: XY12345678']
                                }
                            />
                        </div>
                    </Widget>


                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Adószám"
                                variant="outlined"
                                onChange={this.handleNTAKDataChange}
                                name="ntakTaxNumber"
                                fullWidth
                                value={this.state.shop.ntakData.ntakTaxNumber || ""}
                                required={this.state.shop.ntakData.ntakDataSendingEnabled}
                                validators={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['required', 'matchRegexp:^\\d{11}$']
                                    : ['matchRegexp:^\\d{11}$']
                                }
                                errorMessages={this.state.shop.ntakData.ntakDataSendingEnabled
                                    ? ['A mező kitöltése kötelező', 'A mezőnek 11 szám karakterből kell állnia pl.: 11223344122']
                                    : ['A mezőnek 11 szám karakterből kell állnia pl.: 11223344122']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>
                    <Widget>
                        <div className="flex-1">
                            <DropzoneArea
                                key={this.state.shop.id}
                                filesLimit={1}
                                acceptedFiles={['.cer']}
                                maxFileSize={5000}
                                showFileNames
                                initialFiles={this.state.shop.ntakData.certFile ? [this.getFileObject(this.state.shop.ntakData.certFile)] : undefined}
                                dropzoneText=".cer fájl feltöltése"
                                onChange={this.onSelectCertFile}
                                onDelete={this.onDeleteCertFile}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <DropzoneArea
                                key={this.state.shop.id}
                                filesLimit={1}
                                acceptedFiles={['.key']}
                                maxFileSize={5000}
                                showFileNames
                                initialFiles={this.state.shop.ntakData.keyFile ? [this.getFileObject(this.state.shop.ntakData.keyFile)] : undefined}
                                dropzoneText=".key fájl feltöltése"
                                onChange={this.onSelectKeyFile}
                                onDelete={this.onDeleteKeyFile}
                            />
                        </div>
                    </Widget>
                </Card>

                <div style={{ marginTop: 16}}/>

                <Card title="Blokknyomtató csatlakoztatás">
                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Cégnév"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleReceiptChange}
                                name="companyName"
                                value={this.state.shop.receiptData.companyName || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Adószám"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleReceiptChange}
                                name="taxNumber"
                                value={this.state.shop.receiptData.taxNumber || ""}
                                required
                                validators={['required']}
                                errorMessages={['A mező kitöltése kötelező']}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <TextValidator
                                label="Email"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleReceiptChange}
                                name="email"
                                value={this.state.shop.receiptData.email || ""}
                                validators={['isEmail']}
                                errorMessages={['Érvényes email címet kell megadni']}
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1">
                            <TextValidator
                                label="Honlap"
                                variant="outlined"
                                fullWidth
                                onChange={this.handleReceiptChange}
                                name="homepage"
                                value={this.state.shop.receiptData.homepage || ""}
                            />
                        </div>
                    </Widget>

                    <Widget>
                        <div className="flex-1">
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={this.state.shop.printEur || false }
                                        onChange={(event) => {
                                            const { shop } = this.state;
                                            shop.printEur = event.target.checked;
                                            this.setState({ shop });
                                        }}
                                        color="primary"
                                        name="printEUR" />}
                                label="Euró érték blokkra nyomtatása"
                            />
                        </div>
                        <div className="m-8"/>
                        <div className="flex-1"/>
                    </Widget>

                    <TextValidator
                        label="Egyedi fejléc"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="header"
                        value={this.state.shop.receiptData.header || ""}
                        onChange={this.handleReceiptChange}
                    />

                    <TextValidator
                        label="Egyedi lábléc"
                        multiline
                        fullWidth
                        minRows={3}
                        maxRows={4}
                        variant="outlined"
                        name="footer"
                        margin="normal"
                        value={this.state.shop.receiptData.footer || ""}
                        onChange={this.handleReceiptChange}
                    />

                    <DropzoneArea
                        filesLimit={1}
                        key={this.state.shop.id}
                        acceptedFiles={['image/*']}
                        maxFileSize={300000}
                        initialFiles={this.state.shop.receiptData.imageId ? [`${BASE_URL}/api/images/${this.state.shop.receiptData.imageId}`] : undefined}
                        dropzoneText="Logó feltöltés"
                        onChange={this.onSelectImage}
                        onDelete={this.onDeleteImage}
                    />
                </Card>

                <SaveCancelButtons
                    error={this.state.error}
                    isSubmitInProgress={this.state.isSubmitInProgress}
                    onCancel={() => this.props.history.goBack()}
                />
            </ValidatorForm>
        </>
    }

    render() {
        return <section className="main-content">
            <section className="main-content-body">
                <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                {this.renderEdit()}
            </section>

            <Footer/>
        </section>;
    }
}
