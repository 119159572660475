import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core/styles";
import {Button, CircularProgress, IconButton, Link, Typography} from "@material-ui/core";
import {TextValidator, ValidatorForm} from "react-material-ui-form-validator";
import {FaFacebookSquare, FaInstagramSquare, FaLinkedin} from "react-icons/fa";
import {doLogin} from "../util/axiosSetup";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        width: '1100px',
        margin: 10,
        flexDirection: "row",
        backgroundColor: 'white',
        textAlign: "center",
        borderRadius: 5,
        [theme.breakpoints.down('md')]: {
            flex: 1,
            width: 'auto',
            flexDirection: "column",
        },
    },
    contentSection: {
        flex: 1.5,
        border: '2px solid white',
        borderRadius: '5px 0px 0px 5px',
        paddingTop: 140,
        [theme.breakpoints.down('md')]: {
            display: "none",
        },
    },
    formSection: {
        flex: 1,
        paddingTop: 24,
        paddingBottom: 8,
        borderRadius: '0px 5px 5px 0px',
        [theme.breakpoints.down('md')]: {
            borderRadius: 5,
        },
    },
    divider: {
        width: 3,
        backgroundColor: "#F2F2F2",
    }
}));

function LoginPage(props){
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [formSubmitted, setFormSubmitted] = useState(false);

    const onLoginSuccess = () => {
        const role = sessionStorage.getItem("role");
        switch (role) {
            case "ROLE_ADMIN":
                props.history.push("/admin");
                break;
            default:
                props.history.push("/customer");
        }
    };

    const onLoginFailure = (errorMessage) => {
        if(errorMessage != undefined && errorMessage != null) setError(errorMessage)
        else setError("Sikertelen belépés")
        setFormSubmitted(false);
    };

    const onSubmit = () => {
        // TODO teszteléshez
        // sessionStorage.setItem("log", "frombahu")
        if(!formSubmitted){
            setFormSubmitted(true);
            doLogin(email, password, onLoginSuccess, onLoginFailure)
        }
    };

    return(
        <div className="login-area shadow flex justify-center align-center">

            <div className={useStyles().root}>
                <section className={useStyles().contentSection}>
                    <img src='/img/logo_black.svg' alt='logo' style={{width: 400}}/>
                </section>

                <section className={useStyles().divider}>
                <></>
                </section>

                <section className={useStyles().formSection}>
                    <Typography variant="h4" paragraph>Üdvözlünk!</Typography>
                    <Typography variant="body2" style={{marginBottom: 16}}>A belépéshez add meg az email címed és jelszavadat.</Typography>
                    <ValidatorForm onSubmit={onSubmit}>
                        <TextValidator
                            label="Email"
                            variant="outlined"
                            margin="normal"
                            onChange={(event) => {
                                setEmail(event.target.value);
                            }}
                            style={{width: "80%"}}
                            name="email"
                            value={email}
                            validators={['required', 'isEmail']}
                            errorMessages={['A mező kitöltése kötelező', 'Érvénytelen email']}
                        />
                        <TextValidator
                            label="Password"
                            variant="outlined"
                            onChange={(event) => {
                                setPassword(event.target.value);
                            }}
                            margin="normal"
                            style={{width: "80%"}}
                            name="password"
                            type="password"
                            value={password}
                            validators={['required']}
                            errorMessages={['A mező kitöltése kötelező']}
                        />
                        <section style={{color: "#ff2a00", textAlign: "center"}}>
                            {error}
                        </section>

                        {!formSubmitted
                            ? <Button type="submit" variant="contained"
                                      style={{color: 'white', marginTop: 30, width: 250, height: 60, fontSize: 20, backgroundColor: "#24AE60"}}>Belépés</Button>
                            : <div style={{marginTop: 30, textAlign: "center"}}>
                                <CircularProgress color="primary"/>
                            </div>
                        }
                    </ValidatorForm>

                    <Typography variant="body2" style={{marginTop: 50, color: '#0000008a'}}>Kövess bennünket a social médiában</Typography>
                    <div style={{display: "flex", justifyContent: "center"}}>
                        <IconButton title="Facebook" style={{color: "#24AE60"}} onClick={() => window.open('https://www.facebook.com/bythepay/')}>
                            <FaFacebookSquare/>
                        </IconButton>
                        <IconButton title="Instagram" style={{color: "#24AE60"}} onClick={() => window.open('https://www.instagram.com/bythepay/')}>
                            <FaInstagramSquare/>
                        </IconButton>

                        <IconButton title="Linkedin" style={{color: "#24AE60"}} onClick={() => window.open('https://www.linkedin.com/company/bythepay/')}>
                            <FaLinkedin/>
                        </IconButton>
                    </div>

                    <Typography variant="body2" style={{marginTop: 10, color: '#0000008a'}}>Bythepay © 2025</Typography>
                    <Typography>
                        <Link variant="body2" style={{color: "#24AE60"}} target="_blank" href="https://www.bythepay.hu">www.bythepay.hu</Link>
                    </Typography>
                </section>
            </div>
        </div>
    );
}

export default LoginPage;
