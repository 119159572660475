import React from "react";
import {axiosCommon} from "../../../util/axiosSetup.js";
import {BASE_URL} from "../../../util/Urls";
import getGeneralError from "../../../util/getGeneralError";
import Footer from "../../../components/Footer";
import Search from "../../../components/Search";
import {
    Paper,
    Toolbar,
    Typography
} from "@material-ui/core";
import Card from "../../../components/Card";
import Widget from "../../../components/Widget";
import RowsPerPageOptions from "../../../util/RowsPerPageOptions";
import NavHeader from "../../../components/NavHeader";
import {
    getKeyword,
    getPageNumber,
    getRowsPerPage, removeSessionKeys,
    saveSessionKey
} from "../../../util/SessionStorageHandler";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import RemoveCircle from "@material-ui/icons/RemoveCircle";
import { DataGrid, GridToolbarContainer, GridToolbarExport } from "@material-ui/data-grid";
import NoData from "../../../components/NoData";

export default class AdminShopListPage extends React.Component {
    constructor(props) {
        super();
        this.state = {
            keyword: "",
            shops: [],
            error: null,
            rowsPerPage: RowsPerPageOptions.defaultPageSize,
            page: 0,
            columns: [
                {field: 'shop', headerName: 'Üzlet', width: 160},
                {field: 'owner', headerName: 'Tulajdonos', align: 'center', headerAlign: 'center', width: 160},
                {field: 'email', headerName: 'Email', align: 'center', headerAlign: 'center', width: 200,},
                {field: 'company', headerName: 'Cég', align: 'center', headerAlign: 'center', width: 160,},
                {field: 'enabled', headerName: 'Állapot', align: 'center', headerAlign: 'center', width: 110},
                {field: 'terminals', headerName: 'Max. Terminál szám', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120},
                {field: 'cashregister', headerName: 'Pénztárgép', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},
                {field: 'fiscalprinter', headerName: 'Adóügyi nyomtató', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},
                {field: 'payterminal', headerName: 'Kártya terminál', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},
                {field: 'billing', headerName: 'Áfa-s számla', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},
                {field: 'scale', headerName: 'Mérleg', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},
                {field: 'nfc', headerName: 'NFC', align: 'center', headerAlign: 'center', flex: 1, minWidth: 120, renderCell: this.renderTrueFalse},],
            rows: [],
            isLoading: false,
        }
        this.keywordKey = 'adminShopListPageKeyword'
        this.pageKey = 'adminShopListPageKey'
        this.rowsPerPageKey = 'adminShopListPageRowsPerPageKey'
    }

    componentDidMount() {
        this.setState({ isLoading: true})
        axiosCommon(`${BASE_URL}/api/admin/shops`)
        .then(response => {
            const shops = response.data.items
            let page = 0
            let rowsPerPage = RowsPerPageOptions.defaultPageSize
            let keyword = ""
            if(this.props.history.action === "POP") {
                page = getPageNumber(this.pageKey)
                rowsPerPage = getRowsPerPage(this.rowsPerPageKey)
                keyword = getKeyword(this.keywordKey)
            }else{
                removeSessionKeys([this.pageKey, this.rowsPerPageKey, this.keywordKey])
            }
            this.setState({ shops, page, rowsPerPage, keyword}, () => this.refreshData())
        })
        .catch(error => {
            this.setState({error})
        })
    }

    componentWillUnmount(){
        saveSessionKey(this.keywordKey, this.state.keyword)
        saveSessionKey(this.pageKey, this.state.page)
        saveSessionKey(this.rowsPerPageKey, this.state.rowsPerPage)
    }

    refreshData = () => {
        this.setState({ isLoading: true})

        const filteredShops = this.state.shops.filter(item =>
            `${item.ownerLastName} ${item.ownerFirstName}`.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.ownerEmail.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.name.toLowerCase().includes(this.state.keyword.toLowerCase()) ||
            item.ownerCompany.toLowerCase().includes(this.state.keyword.toLowerCase())
        )

        const rows = filteredShops.map((e, i) => {
            return {
                id: e.id,
                shop: e.name,
                owner: `${e.ownerLastName} ${e.ownerFirstName}`,
                email: e.ownerEmail,
                company: e.ownerCompany,
                enabled: e.enabled ? "Aktív" : "Szünetel",
                terminals: e.allowedTerminalNumber,
                cashregister: e.isCashRegisterAllowed,
                fiscalprinter: e.isFiscalPrinterAllowed,
                payterminal: e.isPayTerminalAllowed,
                billing: e.isBillingAllowed,
                scale: e.isScaleAllowed,
                nfc: e.isNfcAllowed,
            }
        });

        this.setState({ rows, isLoading: false })
    };

    setKeyword = (event) => {
        this.setState({ keyword: event.target.value, page: 0 }, () => this.refreshData())
    };

    initiateEdit = (id) => {
        this.props.history.push({
            pathname: "/admin/people/editshops",
            shopId: id,
        });
    };

    handleChangeRowsPerPage = (entry) => {
        this.setState({ rowsPerPage: parseInt(entry, 10), page: 0});
    };

    renderTrueFalse = (params) => {
        return params.value
            ? <CheckCircleOutline style={{color: "#4caf50"}} />
            : <RemoveCircle style={{color: "#f44336"}}/>
    }

    renderList = () => {
        return this.state.error ?
            <section className="error">{getGeneralError(this.state.error)}</section>
            :
            <>
                <Widget>
                    <Card title="Keresés név / email / tulajdonos / cég alapján">
                        <Search
                            setKeyword={this.setKeyword}
                            refreshData={this.refreshData}
                            value={this.state.keyword}
                        />
                    </Card>
                    <div style={{flex: 1}}/>
                </Widget>

                <Paper>
                    <Toolbar style={{display: 'flex', flexDirection: 'row', }}>
                        <Typography variant="h5" id="tableTitle" component="div">
                            Üzletek
                        </Typography>
                    </Toolbar>
                    <DataGrid
                        columns={this.state.columns}
                        rows={this.state.rows}
                        className="cursor-pointer"
                        disableSelectionOnClick
                        disableColumnMenu
                        autoHeight
                        onPageSizeChange={(entry) =>
                            this.setState({ rowsPerPage: parseInt(entry, 10), page: 0})}
                        onPageChange={(entry) =>
                            this.setState({ page: parseInt(entry, 10)}, () => this.refreshData())}
                        rowsPerPageOptions={RowsPerPageOptions.items}
                        pageSize={this.state.rowsPerPage}
                        pagination
                        page={this.state.page}
                        loading={this.state.isLoading}
                        onRowDoubleClick={(rowData) => this.initiateEdit(rowData.row.id)}
                        components={{
                            Toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <GridToolbarExport/>
                                    </GridToolbarContainer>
                                );
                            },
                            NoRowsOverlay: NoData,
                        }}
                    />
                </Paper>
            </>
    }

    render() {
        return <>
            <section className="main-content">
                <section className="main-content-body">
                    <NavHeader changeSidebarToggled={this.props.changeSidebarToggled}/>
                    {this.renderList()}
                </section>

                <Footer/>
            </section>
        </>;
    }
}
